<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      id="print"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه الاشعارات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>

      <Column field="createdAt" header="تاريخ الاشعار" :sortable="true">
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.createdAt) }}
        </template>
      </Column>
      <Column field="usersId.name" header="مستلم الاشعار" :sortable="true" />
      <Column field="show" header="تم المشاهده" :sortable="true">
        <template #body="slotProps">
          <p v-if="slotProps.data.show">تم</p>
          <p v-else>ليس بعد</p>
        </template>
      </Column>
      <Column field="open" header="تم فتح الاشعار" :sortable="true">
        <template #body="slotProps">
          <p v-if="slotProps.data.open">تم</p>
          <p v-else>ليس بعد</p>
        </template>
      </Column>
      <Column field="usersId.name" header="مستلم الاشعار" :sortable="true" />
      <Column field="name" header="وصف الاشعارات">
        <template #body="slotProps">
          {{ slotProps.data.name }} #{{ slotProps.data.code }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      citiesList: [],
      usersList: [],
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      console.log(document.querySelectorAll('link[rel="stylesheet"], style'));

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    getData() {
      this.$http.get(`notifications/getReport`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
